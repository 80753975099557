<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사용여부 -->
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="사용여부"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="저장시설 목록"
      tableId="storage-facility"
      :columns="grid.columns"
      :data="grid.data"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" :showLoading="false" label="추가" icon="add" @btnClicked="add" />
          <c-btn 
            v-if="editable" 
            label="저장" 
            icon="save" />
          <!-- <c-btn 
            v-if="editable" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="grid.data"
            mappingType="PUT"
            label="저장" 
            icon="save" 
            @beforeAction="saveRiskHazard"
            @btnCallback="saveRiskHazardCallback"/> -->
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'storage-facility',
  data() {
    return {
      searchParam: {
        plantCd: null,
        useFlag: 'Y',
      },
      grid: {
        columns: [
          {
            name: 'plantCd',
            field: 'plantCd',
            label: '사업장',
            align: 'center',
            style: 'width:140px',
            sortable: true,
            required: true,
            type: 'plant'
          },
          {
            name: 'col1',
            field: 'col1',
            label: '저장시설',
            align: 'left',
            style: 'width:300px',
            sortable: true,
            required: true,
            type: 'text',
          },
          {
            name: 'col2',
            field: 'col2',
            label: '위치',
            style: 'width:150px',
            align: 'left',
            sortable: true,
            type: 'text',
          },
          {
            name: 'col3',
            field: 'col3',
            label: '비고',
            style: 'width:200px',
            align: 'left',
            sortable: true,
            type: 'text',
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            style: 'width:90px',
            sortable: true,
            type: 'check',
            true: 'Y',
            false: 'N',
          },
        ],
        data: [],
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      editable: true,
      listUrl: '',
      saveUrl: '',
      isSave: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.ram.riskHazard.list.url
      this.saveUrl = transactionConfig.ram.riskHazard.update.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.grid.data = [];
      let max = this.$comm.generateRandom(10, 20);
      for (let i = 1; i <= max; i++) {
        this.grid.data.push({ plantCd: '1000', col1: '탱크' + i, col2: '위치' + i, col3: '', useYn: 'Y' },)
      }
      // this.$http.url = this.listUrl;
      // this.$http.type = 'GET';
      // this.$http.param = this.searchParam;
      // this.$http.request((_result) => {
      //   this.grid.data = _result.data;
      // },);
    },
    add() {
      let date = new Date();
      if (!this.grid.data) {
        this.grid.data = [];
      }
      this.grid.data.splice(0, 0, {
        id: String(date.getTime()),  // 유해위험요인 번호
        plantCd: '1000',  // 유해위험요인명
        col1: '',  // 유해위험요인 분류 코드
        col2: '',  // 관련근거(법적기준)
        col3: '',  // 관련근거(법적기준)
        useFlag: 'Y',  // 사용여부
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        editFlag: 'C'
      })
    },
    saveRiskHazard() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?', // 저장하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.grid.data, item => {
              item.regUserId = this.$store.getters.user.userId
              item.chgUserId = this.$store.getters.user.userId
            })
            
            this.isSave = !this.isSave
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveRiskHazardCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
  }
};
</script>
